import { createAsyncThunk } from "@reduxjs/toolkit";
import companiesApi from "../../../../configs/apis/companies";

export const getApprovals = createAsyncThunk(
  "approvals/getApprovals",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .getApprovals({ page: payload.page, length: payload.length, search: payload.search, searchColumn: payload.searchColumn, statusFilter: payload.statusFilter })
      .then((response) => response)
      .catch((error) => rejectWithValue(error))
);

export const putApproval = createAsyncThunk(
  "approvals/putApproval",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .putApproval({
        id: payload.id,
        status: payload.status,
        remarks: payload.remarks,
        rejectModules: payload.rejectModules,
        planUuid: payload.planUuid,
        selectedAccessRights: payload.selectedAccessRights,
        isTestingAccount: payload.isTestingAccount,
      })
      .then((response) => response)
      .catch((error) => rejectWithValue(error))
);

export const getAvailablePlans = createAsyncThunk(
  "approvals/getAvailablePlans",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .getAvailablePlans()
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);

export const getApprovalAvailableAccessRight = createAsyncThunk(
  "approvals/getApprovalAvailableAccessRight",
  async (payload, { rejectWithValue }) =>
    companiesApi
      .getApprovalAvailableAccessRight(payload)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error))
);
