import axios from "axios";

export default function putApproval({
  id,
  status,
  remarks,
  rejectModules,
  planUuid,
  selectedAccessRights,
  isTestingAccount,
}) {
  return new Promise((resolve, reject) =>
    axios
      .put(`/account/system-admin/api/v1/approval/${id}`, {
        status,
        remarks,
        reject_modules: rejectModules,
        plan_uuid: planUuid,
        selected_access_rights: selectedAccessRights,
        is_testing_account: isTestingAccount,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.message))
  );
}
